import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { WORKSPACE_INVITE_USERS_PAGE_URL } from "constants/routes";
import PageSectionHeader from "pages/common/PageSectionHeader";
import Button from "components/editorComponents/Button";
import PageWrapper from "pages/common/PageWrapper";

import { getCurrentUser } from "selectors/usersSelectors";

export function WorkspaceMembers() {
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);

  return (
    <PageWrapper displayName="Users">
      <PageSectionHeader>
        <h2>Users</h2>
        {currentUser?.isSuperUser && (
        <Button
          filled
          icon="plus"
          iconAlignment="left"
          intent="primary"
          onClick={() => history.push(WORKSPACE_INVITE_USERS_PAGE_URL)}
          text="Invite Users"
        />
        )}
      </PageSectionHeader>
    </PageWrapper>
  );
}

export default WorkspaceMembers;
